.is-pc {
  @apply flex
}
.is-sp {
  @apply hidden
}
.hamburger-menu {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 999;
}
.hamburger-menu>span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 35px;
  height: 4px;
  background: linear-gradient(135deg, rgb(135, 207, 255) 0%, rgb(255, 187, 244) 100%) 0px 0px / 100% 100%;
  background: -webkit-linear-gradient(135deg, rgb(135, 207, 255) 0%, rgb(255, 187, 244) 100%) 0px 0px / 100% 100%;
}
.hamburger-menu,
.hamburger-menu>span {
  transition: all .4s;
}
.hamburger-menu>span:nth-child(1) {
  top: 20%;
}
.hamburger-menu>span:nth-child(2) {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.hamburger-menu>span:nth-child(3) {
  bottom: 20%;
}
  
/* --- アニメーションパターン --- */
#menu01.active span {
  left: 0;
}
#menu01.active span:nth-of-type(1) {
  transform: translateY(13px) rotate(-45deg);
}
#menu01.active span:nth-of-type(2) {
  left: 200%;
  opacity: 0;
  transform: translateY(10px);
  animation: active-menu01-bar01 .8s forwards;
}
@keyframes active-menu01-bar01 {
  100% {
    height: 0;
  }
}
#menu01.active span:nth-of-type(3) {
  transform: translateY(-13px) rotate(45deg);
}
@media (max-width: 992px) {
  .is-pc {
    @apply hidden
  }
  .is-sp {
    @apply block
  }
}
.sp-bg {
  background: linear-gradient(45deg, rgb(227, 244, 255) 0%, rgb(255, 223, 250) 100%) 0px 0px / 100% 100%;
  background: -webkit-linear-gradient(45deg, rgb(227, 244, 255) 0%, rgb(255, 223, 250) 100%) 0px 0px / 100% 100%;
}