.event-card {
  position: relative;
  margin: 5px;
  display: flex;
  background-color: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: -4px 4px 5px 0px #b7b7b7a0;
  .detail-area {
    width: 70%;
    text-align: start;
    padding: 10px;
    color: #4a4a4a;
    position: relative;
    .event-title {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-decoration: none;
      margin: 5px 0 10px 0;
      font-weight: 700;
    }
    .footer-area {
      position: absolute;
      bottom: 10px;
      .event-date {
        font-weight: 500;
        margin-top: 5px;
      }
      .event-time {
        font-weight: 500;
        margin-top: 5px;
      }
      .event-place {
        font-weight: 500;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        margin-top: 5px;
      }
    }
  }
}
.event-card:hover {
  transform: scale(1.05,1.05);
  transition-duration: 0.5s;
}
